import { GetServerSidePropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { CraftGlobals } from 'tsconfig/craft-types'
import { C404 } from 'ui'
import { useTranslation } from 'next-i18next'
import * as CONST from 'ui/constants/index'
import { getSiteInfo, useSiteInfo } from 'shared'
import getGlobals from '../graphql/get-globals'
import DefaultLayout from '../layouts/default'

type Context = {
  locale: string
}

export async function getStaticProps(context: Context) {
  const siteInfo = getSiteInfo(context.locale ?? '')

  const global = !/^.+-evo$/.test(context.locale ?? '')
    ? await getGlobals(siteInfo?.handle)
    : {}

  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? '', ['common'])),
      global,
    },
  }
}

type Props = {
  global: CraftGlobals
}

export default function Error404({ global }: Props) {
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()

  return (
    <DefaultLayout global={global}>
      <Head>
        <title>{t('find-a-doctor')}</title>
      </Head>
      <C404
        theme={siteInfo?.layout === CONST.SITE_LAYOUT.ICL ? 'teal' : 'pink'}
      />
    </DefaultLayout>
  )
}
